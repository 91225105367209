@media (min-width: 1030px){.container {
  --container-size: 100%;
}

.container-small {
  --container-size: 930px;
}

:root {
  --grid-gap: $size-xl;
}

.col-md-1 {
  --grid-col: 1;
}

.col-md-2 {
  --grid-col: 2;
}

.col-md-3 {
  --grid-col: 3;
}

.col-md-4 {
  --grid-col: 4;
}

.col-md-5 {
  --grid-col: 5;
}

.col-md-6 {
  --grid-col: 6;
}

.col-md-7 {
  --grid-col: 7;
}

.col-md-8 {
  --grid-col: 8;
}

.col-md-9 {
  --grid-col: 9;
}

.col-md-10 {
  --grid-col: 10;
}

.col-md-11 {
  --grid-col: 11;
}

.col-md-12 {
  --grid-col: 12;
}

.hidden-desktop {
  display: none;
}

.display-desktop.hidden {
  display: inline-block;
}

.card-push {
  --card-grid-rows: 5.4rem minmax(100px, auto) 1fr;
}

.list label {
  display: none;
}

.tag-taxo-list {
  --gap-list: 1.6rem 2.4rem;
}

.term-list.list-2-col,
.term-list-double.list-2-col {
  --column-count: 2;
}

.term-list.list-3-col,
.term-list-double.list-3-col {
  --column-count: 3;
}

.term-list.list-4-col,
.term-list-double.list-4-col {
  --column-count: 4;
}

#footer-navigation {
  --gap: 8rem;
}}@media (min-width: 1280px){.container {
  --container-size: 1120px;
}

.container-big {
  --container-size: 1250px;
}

.col-lg-1 {
  --grid-col: 1;
}

.col-lg-2 {
  --grid-col: 2;
}

.col-lg-3 {
  --grid-col: 3;
}

.col-lg-4 {
  --grid-col: 4;
}

.col-lg-5 {
  --grid-col: 5;
}

.col-lg-6 {
  --grid-col: 6;
}

.col-lg-7 {
  --grid-col: 7;
}

.col-lg-8 {
  --grid-col: 8;
}

.col-lg-9 {
  --grid-col: 9;
}

.col-lg-10 {
  --grid-col: 10;
}

.col-lg-11 {
  --grid-col: 11;
}

.col-lg-12 {
  --grid-col: 12;
}

.card-job-offer:not(.mobile) {
  --card-grid-columns: 11rem 58rem 1fr;
}

.card-job-offer:not(.mobile) .card-content {
  grid-column-start: 2;
}

.card-job-offer:not(.mobile) .card-action {
  justify-self: flex-end;
}

#footer-navigation {
  margin-left: 2.8rem;
  margin-right: 2.8rem;
  margin-bottom: 2.8rem;
  border-radius: .8rem;
}

.section-hero.container-big {
  --container-size: 1185px;
}

.section-hero.hero-hp img {
  --hero-img-width: 60%;
  right: 60%;
}

.section-hero .searchform-title {
  padding: 5.2rem 14rem 0 var(--hero-padding-left);
}

.rounded-section {
  border-radius: 2.4rem;
}}